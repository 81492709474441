<template>
  <div class="container" element-loading-text="Loading" v-loading="listLoading">
    <BodyContent :pageData="pageData" @initData="initData">
      <div slot="table">
        <div class="table-body-content" style="padding: 20px 20px 10px 20px">
          <div class="table-header">

            <div class="search-box">
              <div class="search-title">考核期</div>
              <el-date-picker
                v-model="requestParams.time"
                type="date"
                placeholder="请选择">
              </el-date-picker>
            </div>
            <div class="search-box">
              <div class="search-title">关键字</div>
              <el-input
                placeholder="请输入方案名称进行查询"
                clearable
                style="width: 250px; margin-right: 20px"
                v-model.trim="requestParams.keyword"
                maxlength="100"
              />
            </div>
            <el-button type="text">重置</el-button>
          </div>

            <div class="card-content">

                <el-card class="box-card" v-for="item in 12" :key="item">
                    <div slot="header" class="clearfix">
                        <span > <i class="el-icon-s-order"></i> 2021年12月业务部门绩效考核</span>
                        <el-button @click="assessment = true" style="float: right; padding: 3px 0" icon="el-icon-edit-outline" type="text"></el-button>
                    </div>
                    <div class="text item">
                       <div class="left">考核期：</div>
                       <div class="right">2021-12</div>
                    </div>
                    <div class="text item">
                       <div class="left">考核起止日期：</div>
                       <div class="right">2021-11-17  ~   2021-12-31</div>
                    </div>
                    <div class="text item">
                       <div class="left">当前评估节点：</div>
                       <div class="right">部门自评</div>
                    </div>
                    <div class="text item">
                       <div class="left">评估人：</div>
                       <div class="right">部门自评</div>
                    </div>
                </el-card>
            </div>
        </div>

        <el-dialog :visible.sync="assessment">
          <div slot="title" class="dialog_title">
            考核详情
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
          <el-descriptions column="2">
              <el-descriptions-item label="方案名称：">2021年12月业务部门绩效考核</el-descriptions-item>
              <el-descriptions-item label="考核起止日期：">2021-11-17  ~   2021-12-31</el-descriptions-item>
              <el-descriptions-item label="评估人：">张三</el-descriptions-item>
              <el-descriptions-item label="评估时间：">2021-11-17  00:00:00</el-descriptions-item>
          </el-descriptions>
          <el-collapse v-model="activeNames">
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="1">
              <el-descriptions>
                  <el-descriptions-item label="评估对象总数：">16个</el-descriptions-item>
              </el-descriptions>
              <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
                <el-table-column align="center" label="考核对象">
                  <template slot-scope="scope">{{scope.row.name}}</template>
                </el-table-column>
                <el-table-column align="center" label="所属地区">
                  <template slot-scope="scope">{{scope.row.createTime}}</template>
                </el-table-column>
                <el-table-column align="center" label="考核报表（模板）">
                  <template slot-scope="scope">{{scope.row.num}}</template>
                </el-table-column>
                <el-table-column align="center" label="考核状态">
                  <template slot-scope="scope">{{scope.row.describe}}</template>
                </el-table-column>
                <el-table-column align="center" label="评估总分">
                  <template slot-scope="scope">{{scope.row.founder}}</template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template >
                      <el-button type="text" icon="el-icon-s-grid" @click="detailed = true"></el-button>
                    </template>
                  </el-table-column>
              </el-table>
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="2">
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="3">
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="4">
            </el-collapse-item>
          </el-collapse>
        </el-dialog>


        <el-dialog :visible.sync="detailed">
          <div slot="title" class="dialog_title">
            评估详情
            <el-button icon="el-icon-rank" type="text" ></el-button>
          </div>
          <el-descriptions column="2">
              <el-descriptions-item label="考核对象">四川省/行政部</el-descriptions-item>
              <el-descriptions-item label="考核提交时间">2021-11-17 </el-descriptions-item>
              <el-descriptions-item label="换算系数">无 </el-descriptions-item>
              <el-descriptions-item label="评估总分">89   分 </el-descriptions-item>
              <br>
              <el-descriptions-item label="评估明细" labelStyle="width:80px" :span="2" contentStyle="flex:1">
                <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                  <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
                  <el-table-column align="center" label="指标分类">
                    <template slot-scope="scope">{{scope.row.name}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="指标名称">
                    <template slot-scope="scope">{{scope.row.createTime}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="分值范围">
                    <template slot-scope="scope">{{scope.row.num}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="指标权重（%）">
                    <template slot-scope="scope">{{scope.row.describe}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="考评打分">
                    <template slot-scope="scope">{{scope.row.founder}}</template>
                  </el-table-column>
                </el-table>
              </el-descriptions-item>
          </el-descriptions>
        </el-dialog>
      </div>
    </BodyContent>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      activeNames:'1',
      detailed:false,
      assessment:false,
      activeName:'first',
      innerVisible:false,
      dialogFormVisible:false,
      listLoading: false,
      stateList: [],
      requestParams: {
        time:'',
        state: "",
      },
      rules:{
        name:[{ required: true, message: '请填写名称' }],
        state:[{ required: true, message: '请选择状态' }],
        obj:[{ type: 'array',required: true, message: '请选择考核对象',trigger: 'change'  }],
      },
      ruleForm:{
        name:'',
        id:'',
        state:false,
        obj:[{},{}]
      },
      options: [],
      pageData: {},
      tableData:[{},{},{}]
    };
  },
  methods: {
    initData() {},
  },
};
</script>
<style lang="scss" scoped>
.card-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /deep/.el-card{
        margin-bottom: 20px;
    }
    .box-card{
        width: 20%;
        .clearfix{
            color:#409EFF
        }
        .text.item{
            margin-bottom: 10px;
            div{
                display: inline-block;
            }
            &>.left{
                width: 35%;
                text-align: right;
            }
            &>.right{
                float: right;
                width: 60%;
            }
        }
    }
}
.title{
    margin-bottom: 5px;
}
.dialog-left{
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  /deep/.el-input__inner{
    border: 0;
    border-bottom: 1px solid #DCDFE6;
  }
  .list{
    padding: 0 5px;
  div{
    padding: 10px;
  }
}
}

</style>
