<template>
    <div class="container" element-loading-text="Loading" v-loading="listLoading">
        <el-tabs v-model="activeName">
            <el-tab-pane label="评估任务" name="first">
                <task></task>
            </el-tab-pane>
            <el-tab-pane label="评估记录" name="second">
                <record></record>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import task from "./components/task.vue"
import record from "./components/record.vue"
export default {
    components:{task,record},
    data(){
        return {
            listLoading:false,
            activeName:'first'
        }
    }
}
</script>